module.exports = {
'dc.title' : 'Title',
'dc.title.alternative' : 'Other title',
'dc.contributor.arranger' : 'Arranger',
'dc.contributor.author' : 'Creator',
'dc.contributor.corpauthor' : 'Creator',
'dc.contributor.other' : 'Creator',
'dc.contributor' : 'Creator',
'dc.creator' : 'Creator',
'dc.advisor' : 'Advisor',
'dc.contributor.cartographer' : 'Cartographer',
'dc.contributor.corpcartographer' : 'Cartographer',
'dc.contributor.commissioningbody' : 'Commissioned by',
'dc.contributor.compiler' : 'Compiler',
'dc.contributor.corpcompiler' : 'Compiler',
'dc.contributor.corpcomposer' : 'Composer',
'dc.contributor.composer' : 'Composer',
'dc.contributor.corpeditor' : 'Editor',
'dc.contributor.editor' : 'Editor',
'dc.contributor.illustrator' : 'Illustrator',
'dc.contributor.corpillustrator' : 'Illustrator',
'dc.contributor.interviewee' : 'Interviewee',
'dc.contributor.interviewer' : 'Interviewer',
'dc.contributor.issuingbody' : 'Issued by',
'dc.contributor.lyricist' : 'Lyricist',
'dc.contributor.corplyricist' : 'Lyricist',
'dc.contributor.member' : 'Member',
'dc.contributor.photographer' : 'Photographer',
'dc.contributor.sponsor' : 'Sponsored by',
'dc.contributor.surveyor' : 'Surveyor',
'dc.contributor.corpsurveyor' : 'Surveyor',
'dc.contributor.translator' : 'Translator',
'dc.person.name' : 'Name',
'dc.person.formattedname' : 'Also known as',
'dc.person.knownas' : 'Also known as',
'dc.identifier.party' : 'Political affiliation',
'dc.identifier.other' : 'Photo number',
'local.identifier.bitstream' : 'Related items',
'dc.relation.ispartof' : 'Collection',
'dc.relation.incollection' : 'Collection',
'dc.relation.incommunity' : 'Collection',
'dc.relation.ispartofseries' : 'Series',
'dc.date.issued' : 'Date',
'dc.coverage.spatial' : 'Location',
'dc.coverage.birthspatial' : 'Place of birth',
'dc.date.birth' : 'Date of birth',
'dc.coverage.enlistment' : 'Place of enlistment',
'dc.date.enlistment' : 'Date of enlistment',
'dc.description.age' : 'Age',
'dc.description.occupation' : 'Occupation',
'dc.coverage.countryofservice' : 'Nation of service',
'dc.description.service' : 'Service',
'dc.description.branch' : 'Service branch',
'dc.description.unit' : 'Unit',
'dc.description.unitnamealternate' : 'Alternative unit name',
'dc.description.unitnameparent' : 'Parent unit',
'dc.description.unitnamesubordinate' : 'Subordinate units',
'dc.identifier.rank' : 'Rank',
'dc.identifier.servicenumber' : 'Service number',
'dc.person.nextofkin' : 'Next of kin',
'dc.date.death' : 'Date of death',
'dc.coverage.deathspatial' : 'Place of death',
'dc.coverage.burialspatial' : 'Place of burial',
'dc.coverage.memorialspatial' : 'Memorial',
'dc.date.arrivednt' : 'Date arrived in NT',
'dc.date.departednt' : 'Date left the NT',
'dc.description' : 'Description',
'dc.description.abstract' : 'Abstract',
'dc.description.ethnicity' : 'Cultural heritage',
'dc.relation.awards' : 'Honours and awards',
'dc.identifier.awards' : 'Honours and awards',
'dc.description.provenancedonorpub' : 'Provenance (Donor)',
'dc.description.provenancepub' : 'Provenance',
'dc.description.biographical' : 'Biographical notes',
'dc.description.history' : 'History',
'dc.description.mapscale' : 'Map scale',
'dc.description.notes' : 'Notes',
'dc.description.tableofcontents' : 'Table of contents',
'dc.language' : 'Language',
'dc.language.austlang' : 'Language',
'dc.subject.apais' : 'Subject',
'dc.subject.apt' : 'Subject',
'dc.subject.lcsh' : 'Subject',
'dc.subject' : 'Subject',
'dc.subject.ft' : 'Subject',
'dc.subject.mesh' : 'Subject',
'dc.subject.other' : 'Subject',
'dcterms.audience' : 'Subject',
'dc.publisher' : 'Publisher name',
'dc.publisher.corporate' : 'Publisher name',
'dc.publisher.place' : 'Place of publication',
'dc.title.edition' : 'Edition',
'dc.source' : 'Source',
'dc.source.volume' : 'Volume',
'dc.description.frequency' : 'Frequency',
'dc.relation.isreplacedby' : 'Now known as',
'dc.relation.replaces' : 'Previously known as',
'dc.format.extent' : 'Digital format',
'dc.format.medium' : 'Format',
'dc.format.mimetype' : 'File type',
'dc.identifier' : 'Other identifier',
'dc.identifier.genealogicalsociety' : 'Genealogical society NT pioneer register',
'dc.identifier.isbn' : 'ISBN',
'dc.identifier.issn' : 'ISSN',
'dc.identifier.ismn' : 'ISMN',
'dc.identifier.ned' : 'NED identifier',
'dc.rights' : 'Use',
'dc.rights.holder' : 'Copyright owner',
'dc.rights.license' : 'License',
'dcterms.accessrights' : 'Access',
'dc.source.neduri' : 'National edeposit',
'dc.relation.relatesto' : 'Related materials',
'dc.relation.uri' : 'Related links',
'dc.relation.localuri' : 'Related links',
'dc.source.uri' : 'Related links',
'dc.identifier.uri' : 'Parent handle'
};
