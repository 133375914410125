export const ADVANCED_FACETS_SHOW_ADVANCED_FACETS = 'ADVANCED_FACETS_SHOW_ADVANCED_FACETS';
export const ADVANCED_FACETS_HIDE_ADVANCED_FACETS = 'ADVANCED_FACETS_HIDE_ADVANCED_FACETS';
export const ADVANCED_FACETS_ADD_DATE = 'ADVANCED_FACETS_ADD_DATE';
export const ADVANCED_FACETS_FACET_SEARCH_START = 'ADVANCED_FACETS_FACET_SEARCH_START';
export const ADVANCED_FACETS_FACET_SEARCH_COMPLETE = 'ADVANCED_FACETS_FACET_SEARCH_COMPLETE';
export const ADVANCED_FACETS_FACET_SEARCH_FAILED = 'ADVANCED_FACETS_FACET_SEARCH_FAILED';
export const ADVANCED_FACETS_FACET_CHECKED = 'ADVANCED_FACETS_FACET_CHECKED';
export const ADVANCED_FACETS_FACET_ADD_DATE = 'ADVANCED_FACETS_FACET_ADD_DATE';

import {updateFacetBatch} from './search';
export {updateFacetBatch};

export let API_ADVANCED_SEARCH_FACETS = '/api/search_facet';

export function advancedFacetAddDate(value) {
  return {
    type: ADVANCED_FACETS_FACET_ADD_DATE,
    value
  };
}

export function advancedFacetSelectFacet(key, value, isChecked) {
  return {
    type: ADVANCED_FACETS_FACET_CHECKED,
    key, value, isChecked,
  };
}

export function showAdvancedFacets(menu) {
  return async (dispatch, getState) => {

    if (menu === '') {
      menu = 'ntdl_type';
    }

    const {lastQuery} = getState().search;
    dispatch({type: ADVANCED_FACETS_SHOW_ADVANCED_FACETS, menu, lastQuery});
    await advancedFacetSearch('')(dispatch, getState);
  };
}

export function hideAdvancedFacets(save) {
    return async (dispatch, getState) => {
    
    if (save) {
      const {selectedFacets} = getState().advancedFacets;
      await updateFacetBatch(selectedFacets)(dispatch, getState);
    }

    dispatch({type: ADVANCED_FACETS_HIDE_ADVANCED_FACETS});
  };
}

export function addAdvancedFacetCustomDate(startDate, endDate) {
  return {type: ADVANCED_FACETS_ADD_DATE, startDate, endDate};
}

export function advancedFacetSearch(query) {
  return async (dispatch, getState) => {
    const state = getState();
    const {lastQuery, facets} = state.search;
    if (!facets) {
      return;
    }
    const {facetFull, selectedFacets} = state.advancedFacets;

    dispatch({type: ADVANCED_FACETS_FACET_SEARCH_START, query});

    const type = state.advancedFacets.menu;
    if (facetFull[type] !== undefined) {
      dispatch({
        type: ADVANCED_FACETS_FACET_SEARCH_COMPLETE,
        query,
        results: facetFull[type],
      });
      return;
    }

    const initialValues = facets.find(i => i.key === type)?.items;
    if (!initialValues) {
      return;
    }

    // date isn't supported.
     /* commented by Bharath dont't know the use of this code, after commenting it is working fine.

    if (initialValues.length < 10 || type === 'date') {
      facetFull[type] = initialValues;
    }*/

    facetFull[type] = initialValues;

    if (facetFull[type] !== undefined) {
      dispatch({
        type: ADVANCED_FACETS_FACET_SEARCH_COMPLETE,
        query,
        results: facetFull[type],
      });
      return;
    }

    let result;

    try {
      result = await global.ntdl.ajax('post', API_ADVANCED_SEARCH_FACETS, {
        search: lastQuery,
        type,
        query,
      });
    } catch(e) {
      console.log(`AJAX Failed (facet search): ${e.text || e}`);
      console.log('/api/search_facet - failed','API_ADVANCED_SEARCH_FACETS','-lastQuery - ',lastQuery,' - type :',type,' - query : ',query);
      dispatch({type: ADVANCED_FACETS_FACET_SEARCH_FAILED});
      return;
    }
    
    
    if (getState().advancedFacets.menu !== type) {
      // if we are the wrong type then this is going to get quite confusing
      return;
    }

    const facetResultContainer = result?.facets;
    if (facetResultContainer && Object.keys(facetResultContainer).length !== 1) {
      // don't emit failure here. Certain conditions such as searching for
      // stop words seem to cause this.
      return;
    }

    const facetResult = facetResultContainer[Object.keys(facetResultContainer)[0]];

    const finalResult = [];
    for (let key in facetResult) {
      finalResult.push({
        selected: false,
        value: key,
        count: facetResult[key],
      });
    }


    if (query === '' && Object.keys(facetResult).length < 100) {
      facetFull[type] = finalResult;
    }

    dispatch({
      type: ADVANCED_FACETS_FACET_SEARCH_COMPLETE,
      query,
      results: finalResult,
    });
  };
}
