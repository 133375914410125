import {
  ADVANCED_FACETS_HIDE_ADVANCED_FACETS,
  ADVANCED_FACETS_SHOW_ADVANCED_FACETS,
  ADVANCED_FACETS_FACET_SEARCH_START,
  ADVANCED_FACETS_FACET_SEARCH_COMPLETE,
  ADVANCED_FACETS_FACET_SEARCH_FAILED,
  ADVANCED_FACETS_FACET_CHECKED,
  ADVANCED_FACETS_FACET_ADD_DATE,
} from '../actions/advancedFacets';

const initialState = {
  show: false,
  menu: 'ntdl_type',
  query: '',
  facetFull: {},
  facetResults: [],
  selectedFacets: {},
};

function searchComplete(state, results) {
  const query = state.query;
  if (query.length > 0)
  {
    const lower = query.toLowerCase();
    results = results.filter(i => i.value.toLowerCase().indexOf(lower) >= 0);
  }

  const selected = state.selectedFacets[state.menu] || [];
  results.forEach(i => {
    i.selected = selected.indexOf(i.value) >= 0;
  });

  return {
    ...state,
    facetResults: results,
  };
}

function checkFacet(state, key, value, isChecked) {
  const selected = state.selectedFacets[key] || [];
  selected.remove(value);
  if (isChecked) {
    selected.push(value);
  }

  state.selectedFacets[key] = selected;

  if (key === state.menu) {
    const match = state.facetResults.find(i => i.value === value);
    if (match) {
      match.selected = isChecked;
    }
  }

  return {
    ...state,
    facetResults: [...state.facetResults],
  };
}

function showAdvancedFacets(state, menu, lastQuery) {
  if (state.show && state.menu === menu) {
    return state;
  }

  let selectedFacets = state.selectedFacets;
  if (!state.show) {
    selectedFacets = {};
    for (let key in lastQuery.search) {
      selectedFacets[key] = [...lastQuery.search[key]];
    }
  }

  return {
    ...state,
    show: true,
    menu: menu,
    facetResults: [],
    query: '',
    selectedFacets,
  };
}

function addDate(state, value) {
  const date = state.facetFull[date] || [];
  if (date.indexOf(value) >= 0) {
    return;
  }

  const newValue = {value, selected: true};
  date.push(newValue);

  state.facetFull[date] = date;

  let facetResults = state.facetResults;
  if (state.menu === 'date') {
    facetResults = [...facetResults, newValue];
  }

  const dateSelected = state.selectedFacets['date'] || [];
  dateSelected.push(value);
  state.selectedFacets['date'] = dateSelected;

  return {
    ...state,
    facetFull: {...state.facetFull},
    facetResults,
    selectedFacets: {...state.selectedFacets},
  };
}

export default function search(state=initialState, action) {
  switch (action.type) {
    case ADVANCED_FACETS_SHOW_ADVANCED_FACETS:
      return showAdvancedFacets(state, action.menu, action.lastQuery);

    case ADVANCED_FACETS_HIDE_ADVANCED_FACETS:
      return {
        ...state,
        show: false,
        facetFull: {},
        facetResults: [],
      };

    case ADVANCED_FACETS_FACET_SEARCH_START:
      return {
        ...state,
        query: action.query,
      };

    case ADVANCED_FACETS_FACET_SEARCH_COMPLETE:
       return searchComplete(state, action.results);

    case ADVANCED_FACETS_FACET_SEARCH_FAILED:
      return {
        ...state,
        facetResults: [],
      };

    case ADVANCED_FACETS_FACET_CHECKED:
      return checkFacet(state, action.key, action.value, action.isChecked);

    case ADVANCED_FACETS_FACET_ADD_DATE:
      return addDate(state, action.value);

    default:
      return state;
  }
}
