import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1500,
    },
  },
  shadows: ["none"],
  fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  typography: {
    fontFamily: 'Lato, Roboto, Arial, Helvetica, sans-serif',
    fontWeight: 500,
    button: {
      textTransform: 'none'
    },
    citation: {
      fontSize: '12px',
    },
    social: {
      fontSize: '20px',
    }
  },
  palette: {
    /* LANT Grey3 */
    primary: {
      light: '#5b5b5b',
      main: '#333333',
      dark: '#232323',
      contrastText: '#fff',
    },
    /* LANT Lime */
    lime: {
      light: '#bace65',
      main: '#A9C23F',
      dark: '#76872c',
      contrastText: '#fff',
    },
    /* LANT Orange - adjusted for 120% brightness*/
    orange: {
      light: '#FF954A', /* '#d57f43',*/
      main: '#CB6015',
      dark: '#8E2D00', /* '#8e430e',*/
      contrastText: '#fff',
    },
    /* LANT Grey 6 */
    lant_grey_6: {
      light: '#848484',
      main: '#666666',
      dark: '#474747',
      contrastText: '#fff',
    },
    /* LANT Grey A */
    lant_grey_A: {
      light: '#bbbbbb',
      main: '#aaaaaa',
      dark: '#767676',
      contrastText: '#fff',
    },
  },
  get components() {
    return {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            '&.MuiButton-containedLant_grey_A:hover': {
              backgroundColor: this.palette[ownerState.color].main,
              filter: 'brightness(120%)',
            },
            '&.MuiButton-containedOrange:hover': {
              backgroundColor: this.palette[ownerState.color].main,
              filter: 'brightness(120%)',
            },
            '&.MuiButton-outlinedOrange:hover': {
              filter: 'brightness(120%)',
            },
          }),
        },
      },
    }
  },
});
