import {
  COLLAPSE_SEARCH_FACETS,
  SEARCH_COMPLETE,
  SEARCH_FAILED,
  SEARCH_LAST_SEARCH_URL,
  SEARCH_RESET_FACETS_TO_INITIAL_STATE,
  SEARCH_START,
  SEARCH_THUMBNAIL_SIZE,
  SEARCH_LIST_SIZE,
  SEARCH_UPDATE_FACETS_ONCHANGE,
  SELECT_GRID_MAP_DISPLAY,
  SHOW_FILTER_RESULTS_MENU
} from '../actions/search';

const initialState = {
  results: null,
  facets: [],
  message: '',
  count: 0,
  time: 0,
  thumbnailSize: 0.75,
  listSize: 20,
  sortOrder: 'relevance',
  lastSearchUrl: null,
  hasQuery: false,
  lastQuery: {},
  searchIndex: 0,
  initialFacets:[],
  collapseFacets:false,
  filterResultsMenu:false,
  gridMapDisplay: 'grid'
};

function clearSearchResults(state) {
  return {
    ...state,
    results: null,
    count: 0,
    record: null,
  }
}

function mapSelectedFacets(requestFacets, facets, order, total) {
  order.filter(i => facets[i] !== undefined);
  var unique = (v, i, a) => a.indexOf(v) === i;
  const result = order.map(i => {
    const items = [];
    const expected = {};
    const isDate = i === 'date';
    
    for (let key of requestFacets[i] || []) {
      expected[key] = true;
    }
    
    for (let value in facets[i]) {
      const selected = (requestFacets[i] || []).indexOf(value) >= 0;
      const count = facets[i][value];
      delete expected[value];

      if (selected || count <= total) {
        items.push(Object.assign({}, {selected, value, count}));
      }
    }
     
    for (let entry in expected) {
      items.push({
        selected: true,
        value: entry,
      });
    }

    // Sort by selected first then followed by the occurances in descending order
    // or text if date.
    
    items.sort((a, b) => {
      if (a.selected != b.selected) {
        return a.selected ? -1 : 1;
      }

      if (isDate || !a.count || !b.count || a.count === b.count) {
        if (a.value < b.value) {
          return -1;
        } else if (a .value > b.value) {
          return 1;
        }
      }

      return (b.count || 0) - (a.count || 0);
    });
    
    return {key: i, listCount: 3, items};
  });
  result.filter(i => i.items.length > 0);

  return result;
}

function searchComplete(state, result, fullSearchState, newSearch, isAllItems,
                        hasQuery, query) {
  if (state.fullSearchState !== fullSearchState) {
    return state;
  }

  if (!result.success) {
    return {
      ...clearSearchResults(state),
      results: result.results,
      message: 'The search server reported an error',
    }
  }

  const request = result.request || {};
  const requestedFacets = request?.search || {};
  // Note, not including 'language_austlang' facet. NTDL-841
  const facets = mapSelectedFacets(requestedFacets, result.facets || {}, [
    'ntdl_type','collection', 'date', 'language', 'location', 'subject', 'contributor', 'source',
  ], result.total);

  const record = result ? Object.assign({}, result.results[0]) : state.record;

  let sortOrder = 'relevance';
  if ((request.sort ) && (['date_old', 'date_new', 'mod_old', 'mod_new', 'avail_old', 'avail_new', 'oh_asc', 'oh_desc', 'tp_asc', 'tp_desc'].indexOf(request.sort) >= 0)) {
    sortOrder = request.sort;
  }

  let results = result.results;
  if (!newSearch) {
    results = (state.results || []).concat(results);
  }

  let initialFacets = facets;

  const newState = {
    ...state,
    results,
    sortOrder,
    isAllItems,
    facets,
    hasQuery,
    initialFacets,
    lastQuery: query,
    record,
    message:"",
  };

  if (newSearch) {
    newState.count = result.total;
    newState.time = result.time;
  }

  return newState;
}

function searchFailed(state, fullSearchState) {
  if (state.fullSearchState !== fullSearchState) {
    return state;
  }

  const newState = {...state};
  if (!state.results && state.results.length === 0) {
    Object.assign(newState, clearSearchResults(state));
  }

  Object.assign(newState, clearSearchResults(state));
  newState.message = 'There was an error when performing the search';
  return newState;
}

function searchThumbnailSize(state, size) {
  if (typeof localStorage === 'undefined') {
    return {
      ...state,
      thumbnailSize: size,
    }
  } else {
    localStorage.thumbnailSize = size;
    return {
      ...state,
      thumbnailSize: size,
    }
  }
}

function searchListSize(state, listSize) {
  if (typeof localStorage === 'undefined') {
    return {
      ...state,
      listSize: listSize,
    }
  } else {
    localStorage.listSize = listSize;
    return {
      ...state,
      listSize: listSize,
    }
  }
}

function collapseSearchFacets(state, collapseFacets) {
    return {
      ...state,
      collapseFacets:collapseFacets,
    }
}

function selectGridMapDisplay(state, gridMapDisplay) {
    if (typeof localStorage !== 'undefined') {
        localStorage.gridMapDisplay = gridMapDisplay;
    }
    return {
      ...state,
      gridMapDisplay:gridMapDisplay,
    }
}

function showFiltersResultsMenu(state, filterResultsMenu) {
    return {
      ... state,
      filterResultsMenu:filterResultsMenu,
    }
}

// Return facets list matching the inputText in facet search box
function updateFacets(state,facetItem) {
  var currFacets = Array.from(state.initialFacets);
  var keysList = {};
  var notFound = false;
  var item = currFacets.map((i) => {
    if (i.key === facetItem.key) {
      keysList.key = facetItem.key;
      if (facetItem.inputText.length > 0) {
        keysList.items = i.items.filter(
          i => (i.value.toLowerCase().includes(facetItem.inputText.toLowerCase())==true)
        );
        if ((keysList.items.length == 0) && (facetItem.inputText.length == 0)) {
          notFound = true;
        }
      }
    }
  });
  var updatedFacets = [];
  currFacets.map((i) => {
    if (i.key == facetItem.key && notFound == false) {
      updatedFacets.push(keysList);
    } else {
      updatedFacets.push(i);
    }
  });
  updatedFacets.map((i) => {
    if (i.items.length > 3) {
      i.listCount = 3;
    } else {
      i.listCount = i.items.length;
    }
  });
  const newState = {
    ...state,
    facets: updatedFacets
  }
  return newState;
}

export default function search(state=initialState, action) {
  switch (action.type) {
    case SEARCH_START:
      let searchIndex = state.searchIndex;
      if (action.startAfter === undefined) {
        ++searchIndex;
      }

      return {
        ...state,
        searchIndex,
        fullSearchState: action.fullSearchState,
        location: action.location,
      };

    case SEARCH_COMPLETE:
      return searchComplete(state, action?.result || {success: false},
                            action.fullSearchState, action.newSearch,
                            action.isAllItems, action.hasQuery, action.query);

    case SEARCH_FAILED:
      return searchFailed(state, action.fullSearchState);

    case SEARCH_THUMBNAIL_SIZE:
      return searchThumbnailSize(state, action.size);

    case SEARCH_LIST_SIZE:
      return searchListSize(state, action.listSize);

    case COLLAPSE_SEARCH_FACETS:
      return collapseSearchFacets(state, action.collapseFacets);

    case SELECT_GRID_MAP_DISPLAY:
      return selectGridMapDisplay(state, action.gridMapDisplay);

    case SHOW_FILTER_RESULTS_MENU:
      return showFiltersResultsMenu(state, action.filterResultsMenu);
		  
    case SEARCH_LAST_SEARCH_URL:
      return {...state, lastSearchUrl: action.url};

    case SEARCH_UPDATE_FACETS_ONCHANGE:
      //var updatedFacets = updateFacets(state,action.facetItem);
      //return {...state,facets:updatedFacets};
      return updateFacets(state,action.facetItem); 

    case SEARCH_RESET_FACETS_TO_INITIAL_STATE: 
      var updatedFacets = state.initialFacets;
      return {...state,facets:updatedFacets};
      
    default:
      return state;
  }
}
