import React, { Component, Suspense } from 'react';
import CookieConsent from "react-cookie-consent";
const MapView = React.lazy(() => import('./mapview.jsx'));
const Results = React.lazy(() => import('./results.jsx'));
const Viewer = React.lazy(() => import('./viewer.jsx'));
const SearchOptions = React.lazy(() => import('./search-options.jsx'));
const ExternalLink = React.lazy(() => import('./external-link.jsx'));
import {Route, Switch} from 'react-router';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './actions';
import { ConnectedRouter, routerMiddleware, push } from 'react-router-redux';
import qs from 'query-string';
import autobind from 'autobind-decorator';
import {showAdvancedFacets} from './actions/advancedFacets';
import {
  changeListSize,
  changeThumbnailSize,
  collapseSearchFacets,
  search,
  selectGridMapDisplay,
  showFiltersResultsMenu
} from './actions/search';
import {viewerHighlightByQueryInLocation} from './actions/viewer';
import {isMobileBrowser} from './common';

/* The "Grid/Map Results" button only displays if the environment variable is set */
/* Note that "false" is a string and returns a true value... bogus */
const SHOW_MAP = (
  (typeof window !== 'undefined' && window.document && window.document.createElement ) ? (
    Boolean(window.SHOW_MAP) && (window.SHOW_MAP !== 'false')
  ) : (
    Boolean(process.env.SHOW_MAP) && (process.env.SHOW_MAP !== 'false')
  )
);

class App extends Component {

  constructor(props) {
    super(props);

    this.onShowFacetsMenu = this.onShowFacetsMenu.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.toggleSearchOptions = this.toggleSearchOptions.bind(this);
    this.toggleGridMap = this.toggleGridMap.bind(this);
  }

  updateDimensions() {
    if (window.innerWidth >= 576) {
      this.props.collapseSearchFacets(false);
    } else {
      this.props.collapseSearchFacets(true);
    }
    // This is evil on table/phone
    //console.log(`Debug: calling forceUpdate()`);
    //this.forceUpdate()
  }

  /* The "Open/Close Filters" button, upper left of desktop results */
  toggleSearchOptions(collapseFacets) {
    if (collapseFacets) {
      collapseFacets = false;
    } else {
      collapseFacets = true;
    }
    this.props.collapseSearchFacets(collapseFacets);
  }

  /* The "Grid/Map Results" button, upper right of desktop results */
  toggleGridMap(gridMapDisplay) {
    //console.log(`toggleGridMap() gridMapDisplay = ${gridMapDisplay}`);
    if (gridMapDisplay == 'grid') {
      gridMapDisplay = 'map';
    } else {
      gridMapDisplay = 'grid';
    }
    //console.log(`toggleGridMap() gridMapDisplay -> ${gridMapDisplay}`);
    this.props.selectGridMapDisplay(gridMapDisplay);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    if (window.innerWidth >= 576) {
      this.props.collapseSearchFacets(false);
    } else {
      this.props.collapseSearchFacets(true);
    }
    //console.log('app.jsx componentDidMount()');

    // This resets the page to the top. Used to be in viewer.jsx
    // but that causes a reset with every resize, especially on
    // iOS Safari when a resize is triggered by the top & bottom
    // menus during scroll forward/back.
    window.scrollTo(0,0);
    //console.log('app.jsx window.scrollTo(0,0);');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  // Use this to determine why the component is re-rendering
  /*
  componentDidUpdate(prevProps, prevState, snapshot) {
    //if ((window.innerWidth < 576) && (window.innerHeight > window.innerWidth)) {
    //  this.props.collapseSearchFacets(true);
    //}

    // RJG - debug the reason for re-render
    Object.entries(this.props).forEach(([key, val]) =>
      {
      if (prevProps) {
        prevProps[key] !== val && console.log(`app.jsx - componentDidUpdate() - Prop '${key}' changed ${prevProps[key]} -> ${val}`);
      } else {
        console.log(`app.jsx - componentDidUpdate() - prevProps undefined`);
      }
      }
    );
    if (this.state) {
      Object.entries(this.state).forEach(([key, val]) =>
        {
        if (prevState) {
          prevState[key] !== val && console.log(`app.jsx - componentDidUpdate() - State '${key}' changed ${prevState[key]} -> ${val}`);
        } else {
          console.log(`app.jsx - componentDidUpdate() - prevState undefined`);
        }
        }
      );
    }
  }
  */

  onShowFacetsMenu() {
    if (this.props.filterResultsMenu) {
      this.props.showFiltersResultsMenu(false);
    } else {
      this.props.showFiltersResultsMenu(true);
    }
  }

  _monkeyPatchLoad() {
    // monkey patch refine my results in navbar for low resolution devices

    // Set the URL to "/" - really?
    //const navbarClear = document.getElementById('navbar-clear');
    //navbarClear.addEventListener('click', (e) => {
    //  if (this.props.hasQuery) {
    //    e.preventDefault();
    //    location.assign('/');
    //  }
    //});

    const refineMyResults = document.getElementById('refine-my-search');
    const navbarFilter = document.getElementById('navbar-filter');
    for (let i of [refineMyResults, navbarFilter]) {
      i.classList.remove('has-results');
      if (this.props.results?.length > 0) {
        i.classList.add('has-results');
      }
      i.addEventListener('click', this._refineMyResults);
    }
  }

  _monkeyPatchUnload() {
    const refineMyResults = document.getElementById('refine-my-search');
    refineMyResults.classList.remove('has-results');
    refineMyResults.removeEventListener('click', this._refineMyResults);
  }

  @autobind
  _refineMyResults(e) {
    e.preventDefault();
    this.props.showAdvancedFacets('');
  }

  @autobind
  componentWillMount() {
    /* Select Medium cards by default */
    if (typeof localStorage === 'undefined' || localStorage.thumbnailSize === undefined) {
      this.props.changeThumbnailSize(0.75);
    } else {
      this.props.changeThumbnailSize(parseFloat(localStorage.thumbnailSize));
    }
    /* Select 20 items per facet list by default */
    if (typeof localStorage === 'undefined' || localStorage.listSize === undefined) {
      this.props.changeListSize(20);
    } else {
      this.props.changeListSize(parseInt(localStorage.listSize));
    }
    /* Select Grid display by default */
    if (typeof localStorage === 'undefined' || localStorage.gridMapDisplay === undefined) {
      this.props.selectGridMapDisplay('grid');
    } else {
      this.props.selectGridMapDisplay(localStorage.gridMapDisplay);
    }
    /* if SHOW_MAP is false, default to grid view */
    if (SHOW_MAP === false) {
        //console.log(`Forcing grid view, SHOW_MAP is ${SHOW_MAP}`);
        this.props.selectGridMapDisplay('grid');
    } else {
        //console.log(`Allowing map view, SHOW_MAP is ${SHOW_MAP}`);
    }
    //console.log('app.jsx componentWillMount()');
  }

  componentWillUnmount() {
    this._monkeyPatchUnload();
  }

  render() {
    this._monkeyPatchLoad();

    const doSearch = async (location) => {
      //console.log('calling search(' + location.search + ') from app.jsx');
      //console.log(`app.jsx location=${JSON.stringify(location)}`);
      await this.props.search(location.search);

      this.props.viewerHighlightByQueryInLocation(location.search);

      //console.log('app.js render() setting document.body.scrollTop = 0');
      document.body.scrollTop = 0;

      // Set the content of the text search box from the query string:
      // And the (X) clear search control.
      const query = qs.parse(location.search).query || '';

      //const queryField = document.getElementById('search-query');
      const queryField = document.getElementById('search-input');
      queryField.value = query;
      //console.log(`Debug: Setting queryField.value=${query}`);
      //navbar search toggle element is removed
      //document.getElementById('navbar-search-toggle').checked = query !== '';
      if (query !== '') {
        document.getElementById('navbar-clear').style.display = 'block';
      }

      if (this.props.ready) {
        this.props.ready();
      }
    };

    var isDesktop = (window) => {
      if (window.innerWidth >= 1200) {
        return true;
      } else {
        return false;
      }
    };

    var isLandscape = (window) => {
      if (window.innerWidth > window.innerHeight) {
        return true
      } else{
        return false;
      }
    }

    var isOtherDevices = (window) => {
      if ((window.innerWidth >= 576) && (window.innerWidth < 1200)){
        return true;
      } else {
        return false;
      }
    };

    /* The "Open/Close Filters" button, upper left of desktop results, top of mobile results */
    /*  'chevron_left'  = '&#xe5cb;' */
    /*  'chevron_right' = '&#xe5cc;' */
    /*  'clear'         = '&#xe14c;' */
    /*  'expand_more'   = '&#xe5cf;' */
    const openCloseText = (this.props.collapseFacets) ? (
      <span className="open-close-text disable-select">Open Filters</span>
    ) : (
      <span className="open-close-text disable-select">Close Filters</span>
    );
    const openCloseArrow = (this.props.collapseFacets) ? (
      <span className="material-icons open-close-arrow disable-select">&#xe5cc;</span>
    ) : (
      <span className="material-icons open-close-arrow disable-select">&#xe5cb;</span>
    );
    const openCloseClass = (this.props.collapseFacets) ? ('open-close-0') : ('open-close-225');
    const openCloseDiv = (
      <div className={'d-none d-sm-block clickable open-close-button disable-select' + ' ' + openCloseClass} onClick={()=>this.toggleSearchOptions(this.props.collapseFacets)}>
        {openCloseText}{openCloseArrow}
      </div>
    );
    const openCloseMobileText = (this.props.filterResultsMenu == true) ? (
      <span className="open-close-text disable-select">Close Filters</span>
    ) : (
      <span className="open-close-text disable-select">Open Filters</span>
    );
    const openCloseMobileIcon = (this.props.filterResultsMenu == true) ? (
      <span className="material-icons open-close-icon disable-select">&#xe14c;</span>
    ) : (
      <span className="material-icons open-close-icon disable-select">&#xe5cf;</span>
    );
    const openCloseMobileDiv = (
      <div className="no-gutters clickable open-close-mobile disable-select" onClick={this.onShowFacetsMenu}>
        {openCloseMobileText}{openCloseMobileIcon}
      </div>
    );

    /* The "Grid/Map Results" button, upper right of desktop results, top of mobile results */
    /*  'travel_explore' = '&#xe2db;' */
    /*  'grid_view       = '&#xe9b0;' */
    const gridMapText = (this.props.gridMapDisplay == 'grid') ? (
      <span className="grid-map-text disable-select">Show Map</span>
    ) : (
      <span className="grid-map-text disable-select">Show Grid</span>
    );
    const gridMapIcon = (this.props.gridMapDisplay == 'grid') ? (
      <span className="material-icons grid-map-icon disable-select">&#xe2db;</span>
    ) : (
      <span className="material-icons grid-map-icon disable-select">&#xe9b0;</span>
    );
    const gridMapClass = (this.props.gridMapDisplay) ? ('grid-map-0') : ('grid-map-0');

    /* The "Grid/Map Results" button only displays if the environment variable is set */
    const gridMapDiv = (SHOW_MAP === true) ? (
      <div className={'d-none d-sm-block clickable grid-map-button disable-select' + ' ' + gridMapClass} onClick={()=>this.toggleGridMap(this.props.gridMapDisplay)}>
        {gridMapIcon}{gridMapText}
      </div>
    ) : null;
    const gridMapMobileDiv = (SHOW_MAP === true) ? (
      <div className="no-gutters clickable grid-map-mobile disable-select" onClick={()=>this.toggleGridMap(this.props.gridMapDisplay)}>
        {gridMapText}{gridMapIcon}
      </div>
    ) : null;
    //console.log(`SHOW_MAP is ${SHOW_MAP}, gridMapDiv = ${gridMapDiv}`);
    //console.log(`SHOW_MAP is ${SHOW_MAP}, gridMapMobileDiv = ${gridMapMobileDiv}`);

    /* Possibly should combine desktop & mobile facets? */

    /* The desktop facets, displays to the left of results */
    const SearchOptionsDiv = ((this.props.collapseFacets == false) ? (
      <div className="d-flex flex-column position-fixed overflow-auto" id="search-facets">
        <Suspense fallback={<div></div>}>
          <SearchOptions/>
        </Suspense>
      </div>
    ) : null );

    /* The mobile facets, displays as overlay on results */
    const SearchOptionsMobileDiv = ((this.props.filterResultsMenu == true) ? (
      <div className="filter-results-container">
        <div className="no-gutters filter-results-menu">
          <div className="filter-results-mobile">
            <Suspense fallback={<div></div>}>
              <SearchOptions/>
            </Suspense>
          </div>
        </div>
      </div>
    ) : null );

    /* Classes are set for display type */
    const ResultsDivClasses = ((isMobileBrowser()) ? (
      "container-fluid search-results results-outside mobile-display p-0"
    ) : (
      (this.props.collapseFacets == false) ? (
        "col-auto d-flex search-results results-outside"
      ) : (
        "col-auto d-flex w-100 results-outside"
      )
    ));

    /* Prepare for Map Results */

    /* Select type of results display here */
    const ResultsDiv = (
      <div id="map-grid-results-div" className={ResultsDivClasses}>
        {
          (this.props.gridMapDisplay == 'grid') ? (
            <Suspense fallback={<div></div>}>
              <Results/>
            </Suspense>
          ) : (
            <Suspense fallback={<div></div>}>
              <MapView/>
            </Suspense>
          )
        }
      </div>
    );

    /* The actual search application */
    const searchComponent = ({location}) => {
      /* Unused */
      //let LOCAL_URL_PREFIX = (
      //  (typeof window !== 'undefined' && window.document && window.document.createElement ) ? (
      //    window.LOCAL_URL_PREFIX
      //  ) : (
      //    process.env.LOCAL_URL_PREFIX
      //  )
      //);
      //let uparrowpng = `${LOCAL_URL_PREFIX}/common/images/up-arrow.png`;
      //let downarrowpng = `${LOCAL_URL_PREFIX}/common/images/down-arrow.png`;
      //let homebutton = `${LOCAL_URL_PREFIX}/common/images/home.png`;

      // do not perform the search during a render or React will complain.
      setTimeout(() => {
        doSearch(location);
      }, 0);

      // -----------------------------------------------------------------------------
      // Build the final application with each component used only once - NTDL-710
      // -----------------------------------------------------------------------------
      //console.log(`Info: Loading Search App`);
      return (
        <div>
          {
            ((isMobileBrowser()) ? (
              <div className="no-gutters d-xs-block d-sm-none mobile-display" style={{margin:0}}>
                <div className="d-block w-70" style={{opacity:1,backgroundColor:'white',margin:'0 auto',padding:0}}>
                  <div className="no-gutters d-flex" style={{justifyContent:'center'}}>
                    <div className='facets-dropdown'>
                      {openCloseMobileDiv}
                      {gridMapMobileDiv}
                    </div>
                  </div>
                  {SearchOptionsMobileDiv}
                </div>
                {ResultsDiv}
              </div>
            ) : (
              <div className="no-gutters">
                {openCloseDiv}
                {gridMapDiv}
                {SearchOptionsDiv}
                {ResultsDiv}
              </div>
            ))
          }
        </div>
      );
    };

    /* Load the viewer based on the match group of the URL path */
    const loadViewer = ({match}) => {
      //console.log(`Info: Loading Viewer`);
      const params = match.params;
      const handle = `${match.params.handle1}/${match.params.handle2}`;
      return (
        <Suspense fallback={<div></div>}>
          <Viewer ready={this.props.ready} handle={handle} {...params} />
        </Suspense>
      );
    };

    const atsiCookieConsent = (
      <CookieConsent
          location="none"
          acceptOnOverlayClick="true"
          buttonId="atsiOk"
          buttonText="OK"
          containerClasses="atsiContainer"
          contentClasses="atsiContent"
          cookieName="NTDL_ATSI_Agree"
          overlay="true"
          style={{
            alignItems: "end",
            background: "var(--lant_white)",
            color: "var(--lant_black)",
            flexDirection: "column",
            fontFamily: "Kanit, Arial, Helvetica, sans-serif",
            fontSize: "11px",
            height: "auto",
            left: "50%",
            padding: 0,
            transform: "translate(-50%, -40%)",
            top:"40%",
            width: "66%"
          }}
          buttonStyle={{
            backgroundColor: "var(--lant_orange)",
            color: "var(--lant_white)",
            fontSize: "12px",
            margin: "1em"
          }}
          contentStyle={{
            flex: "1",
            margin: "0 1em 0"
          }}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
          expires={1}
          >
        <hr className="lime-hr" />
        Aboriginal and Torres Strait Islander people are advised that this
        website may contain the names, voices and images of people who have
        died, as well as other culturally sensitive content. Please be
        aware that some collection items may use outdated phrases or words
        which reflect the attitude of the creator at the time, and are now
        considered offensive.
      </CookieConsent>
    );

    const gdprCookieConsent = (
      <CookieConsent
          location="bottom"
          acceptOnOverlayClick="true"
          buttonId="gdprOk"
          buttonText="OK"
          containerClasses="gdprContainer"
          contentClasses="gdprContent"
          cookieName="NTDL_GDPR_Agree"
          style={{
            alignItems: "end",
            background: "var(--lant_orange)",
            color: "var(--lant_white)",
            fontFamily: "Kanit, Arial, Helvetica, sans-serif",
            fontSize: "11px"
          }}
          buttonStyle={{
            backgroundColor: "var(--lant_white)",
            color: "var(--lant_black)",
            fontSize: "12px"
          }}
          expires={28}
          >
        We use temporary cookies on this site to provide functionality.<br />
        By continuing to use this site without changing your settings, you consent to our use of cookies.
      </CookieConsent>
    );

    /* Switch between the viewer or search app depending on URL */
    return (
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={this.props.history}>
          <div style={{width:'100%',height:'100%',minHeight:'100%',minWidth:'100%'}}>
            <Switch>
              <Route exact path='/:handle1/:handle2' component={loadViewer} />
              <Route exact path='/:handle1/:handle2/:order' component={loadViewer} />
              <Route exact path='/:handle1/:handle2/:order/:page' component={loadViewer} />
              <Route render={searchComponent} />
            </Switch>
            <Suspense fallback={<div></div>}>
              <ExternalLink />
            </Suspense>
            {atsiCookieConsent}
            {gdprCookieConsent}
          </div>
        </ConnectedRouter>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    hasQuery: state.search.hasQuery,
    results: state.search.results,
    collapseFacets: state.search.collapseFacets,
    gridMapDisplay: state.search.gridMapDisplay,
    filterResultsMenu: state.search.filterResultsMenu,
  }
}

export default connect(mapStateToProps, {
  changeListSize,
  changeThumbnailSize,
  collapseSearchFacets,
  search,
  selectGridMapDisplay,
  showAdvancedFacets,
  showFiltersResultsMenu,
  viewerHighlightByQueryInLocation
})(App);
