import {
  VIEWER_OPEN_EXTERNAL_DIALOG,
  VIEWER_CLOSE_EXTERNAL_DIALOG,
  VIEWER_LOADING,
  VIEWER_COMPLETE,
  VIEWER_FAILED,
  VIEWER_SEARCH_DOCUMENT_START,
  VIEWER_SEARCH_DOCUMENT_COMPLETE,
  VIEWER_SEARCH_DOCUMENT_FAILED,
  VIEWER_PAGE_SEARCH,
  VIEWER_HIGHLIGHT_QUERY,
  VIEWER_ZOOM_PDF,
} from '../actions/viewer';

function clearState(state) {
  return {
    ...state,
    externalLink: null,
    record: null,
    error: null,
    iiifId: null,
    download: null,
    searchDocumentQuery: null,
    searchDocumentError: '',
    searchDocumentPages: [],
    page: 0,
    highlight: '',
    zoomPdf:false,
    related_items:null,
  };
}

function clearSearchDocumentState(state) {
  return {
    ...state,
    searchDocumentError: '',
    searchDocumentPages: [],
  }
}

const initialState = clearSearchDocumentState(clearState({
  request: null,
  searchDocumentHighlight: '',
}));

const initialStartState = {
    request: null,
    searchDocumentHighlight: '',
    externalLink: null,
    record: null,
    error: null,
    iiifId: null,
    download: null,
    searchDocumentQuery: null,
    searchDocumentError: '',
    searchDocumentPages: [],
    page: 0,
    highlight: '',
    zoomPdf:false,
    related_items:null,	
}
 
function closeViewer(state, performAction) {
  if (performAction && state.externalLink !== null) {
    window.open(state.externalLink);
  }

  return {
    ...state,
    externalLink: null,
  };
}

// Lots more comments from here on, it's a bit twisty
export function viewerComplete(state, result, page, zoomPdf) {
  //console.log(`result is ${(result) ? 'Defined' : 'Undefined'}`);

  const record = result ? Object.assign({}, result.results[0]) : state.record;
  //console.log(`record is ${(record) ? 'Defined' : 'Undefined'}`);

  const related_items = result ? result.related_items : state.related_items;
  //console.log(`related_items is ${(related_items) ? 'Defined' : 'Undefined'}`);

  // We need a record to view
  if (!record) {
    //console.log(`viewerComplete() reducer returning state, state.record = ${(state.record) ? 'Defined' : 'Undefined'}`);
    return state;
  } else {
    //console.log(`viewerComplete() reducer record = ${(record) ? 'Defined' : 'Undefined'}`);
  }

  // Default to page 0 if there are many pages
  // Zero is a valid defined value for page
  //console.log(`page is ${(page || page === 0) ? page : 'Undefined'}`);
  if (page === undefined && record.pages && record.pages.length > 0) {
    page = record.pages[0].order;
    //console.log(`set page=${page}`);
  } else {
    //console.log(`record.pages is ${(record.pages) ? 'Defined' : 'Undefined'}`);
    //console.log(`record.pages.length is ${(record.pages?.length) ? record.pages.length : 'Undefined'}`);
  }

  // When we used DSpace as a source, link was a retrieve link:
  // link = https://territorystories.nt.gov.au/rest/bitstreams/21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4/retrieve
  // Now we use S3 as the source, link is an S3 URL:
  // link = https://ntdl-dev-rich.s3.amazonaws.com/ts/ab4/21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4/ph0091-0014.jpg
  //
  // Since JPEG is harvested last, the link will be a JPEG which
  // also makes it load quicker. TIFF can be downloaded.

  const link = record.link;
  //console.log(`link is ${(link) ? 'Defined' : 'Undefined'} = ${link}`);

  // The IIIF Server has changed too
  // iiif_server = http://territorystories1804.local/iiif
  // Now we use /iiifv2 which has an S3 resolver
  // iiif_server = http://territorystories1804.local/iiifv2

  let iiif_server = window.iiif_server;
  if (!iiif_server) {
    iiif_server = process.env.IIIF_SERVER;
  }
  //console.log('iiif_server = ' + iiif_server);

  // The iiifId was the ID of the item to retrieve from DSpace
  // iiifId = 21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4
  // Now with S3 this needs to be matched from the new link, and
  // set to the path name of the file for IIIFv2, or set to null
  // iiifId = 21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4

  let iiifId = null;
 
  if (link) {
    // RJG - this generates lots of output but is good for debugging
    //for (let key of Object.keys(record).sort()) {
    //  console.log(`RJG: ${key}: ${record[key]}`);
    //}
    // The link is now set to S3, not territorystories - Change this:
    //const idMatch = link.match(/territorystories.*bitstreams\/([^\/]+)/);
    //const idMatch = link.match(/.*\.s3\.amazonaws\.com\/ts\/[\w]{3}\/([^\/]+)/);
    const idMatch = link.match(/.*\/ts\/[\w]{3}\/([^\/]+)/);
    //console.log('idMatch = ' + idMatch);

    if (idMatch) {
      // uuid is the matching part of the link.match
      let uuid = idMatch[1];
      //console.log('uuid = ' + uuid);

      // The grouping is the subdir in S3
      //const grouping = record.bitstreamuuid.split('_').pop().substr(record.bitstreamuuid.length - 3);
      let grouping = uuid.split('_').pop().substr(uuid.length - 3);
      //console.log('grouping = ' + grouping);

      // The image name is just the file name (allow for \ as well as / in path)
      let fullname = link.split('\\').pop().split('/').pop();
      //let filename = fullname.split(".")[0];
      let filename = record.name;
      let extnname = fullname.split(".")[1];
      // Use the jpg if present to make the viewer fast
      if (record.hasjpegimage === true) {
        extnname = 'jpg';
      }
      //console.log('fullname = ' + fullname);
      //console.log('filename = ' + filename);
      //console.log('extnname = ' + extnname);

      // The 'ts/' prefix here is HARVEST_PREFIX_URL="ts" from
      // the Territory Stories harvester. Probably should be an
      // environment variable in future...
      // also, we don't use the matched uuid any more, we build
      // the iiifId from the bitstream information...
      //iiifId = idMatch[1];
      //iiifId = `ts/${grouping}/${record.bitstreamuuid}/${record.bitstreamname}`;
      //
      // The Cantaloupe IIIF server doesn't like '/' in the S3 path
      //console.log('IIIF Server = ' + iiif_server);
      const iiifRegexp = RegExp('/cantaloupe/');
      if (iiifRegexp.test(iiif_server)) {
        iiifId = encodeURIComponent(`ts/${grouping}/${uuid}/${filename}.${extnname}`);
      } else {
        iiifId = `ts/${grouping}/${uuid}/${filename}.${extnname}`;
      }
      //console.log('iiifId = ' + iiifId);
    } else {
      //console.log(`Error: Could not match a UUID in ${link}`);
    }
  } else {
    //console.log(`Link is undefined.`);
  }
   
  // Download is just a link, or a list in the case of pictures
  // download = [ https://territorystories.nt.gov.au/rest/bitstreams/21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4/retrieve , http://territorystories1804.local/iiif/21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4/full/full/0/default.jpg?download=Administrators%20Council.jpg ]
  // download = ts/ab4/21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4/ph0091-0014.jpg
  // download = [ https://ntdl-dev-rich.s3.amazonaws.com/ts/ab4/21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4/ph0091-0014.jpg ,  http://territorystories1804.local/iiifv2/ts/ab4/21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4/ph0091-0014.tif/full/full/0/default.tif?download=Administrators%20Council.tif ]

  // The download variable is never even used...
  //let download = record.download ? [record.download] : [record.link];

  // RJG - actually... this is never used.
  let download = null;

  // Then, if its a picture, and we have a matching ID
  //if (record.viewer === 0 && iiifId) {
    // This was for TS names
    //const nameEncoded = encodeURIComponent(`${record.title}.jpg`);
    //const nameEncoded = Administrators%20Council.jpg
    // For S3 names, just use the extension of the name of the bitstreamname
    //const nameEncoded =

    //const nameEncoded = encodeURIComponent(`${record.title}.${record.bitstreamname.substring(record.bitstreamname.lastIndexOf('.') + 1 )}`);
    //console.log('nameEncoded = ' + nameEncoded);

    // This was for using the TS IIIF server
    //download = [
    //  download,
    //  `${iiif_server}/${iiifId}/full/full/0/default.jpg?download=${nameEncoded}`
    //];
    //download = [ https://territorystories.nt.gov.au/rest/bitstreams/21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4/retrieve, http://territorystories1804.local/iiifv2/21b596be-c0c1-4c31-b4b0-58dd7c7b5ab4/full/full/0/default.jpg?download=Administrators%20Council.jpg ]
    //
    // Now using the S3 IIIF server it's slightly different
    // download = [ , ]

    //download = [
    //  download,
    //  `${iiif_server}/${iiifId}/full/full/0/default.${record.bitstreamname.substring(record.bitstreamname.lastIndexOf('.') + 1 )}?download=${nameEncoded}`
    //];

    // RJG - actually... this is never used.
    //download = null;
    //console.log('Download = ' + download);
  //}

  // Viewer = 1 is a PDF file
  if (record.viewer == 1) {
    let linkValue = record.link;
    let npos = linkValue.lastIndexOf('/');
    linkValue = linkValue.slice(0,npos);
    linkValue = linkValue +"/"+ (page).toString() + ".pdf";
    record.link = linkValue;
    //console.log(`set link to ${record.link} as record.viewer == 1`);
  }

  if (!record.all || page >= record.all.length || page < 0) {
    page = 0;
    //console.log(`set page to zero as page was invalid!`);
  }

  if ((zoomPdf == undefined)||(zoomPdf == null)){
      zoomPdf = state.zoomPdf;
  }

  let newState = {
    ...clearState(state),
    record,
    iiifId,
    download,
    page,
    zoomPdf,
    related_items,	
  }

  //console.log(`returning state, state.record = ${(newState.record) ? 'Defined' : 'Undefined'}`);
  return newState;
}

export function searchByPage(state, query) {
  const match = query.match(/\d+/);
  if (match) {
    query = match[0];
  }

  if ((query !== '') && (!state?.record?.all || !match)) {
    return {
      ...clearSearchDocumentState(state),
      searchDocumentError: 'No matching pages',
    };
  }

  const record = state.record;
  if (query === '') {
    return {
      ...clearSearchDocumentState(state),
      searchDocumentPages: record.all,
    };
  }

  const searchDocumentPages = record.all.filter(i => {
    return (i.order+1).toString().indexOf(query) == 0;
  });

  return {
    ...clearSearchDocumentState(state),
    searchDocumentPages,
  };
}

export default function viewer(state=initialStartState, action) {
  //console.log(`viewer reducer switch ${action.type}`);
  switch (action.type) {
    case VIEWER_OPEN_EXTERNAL_DIALOG:
      return {
        ...state,
        externalLink: action.externalLink,
      };

    case VIEWER_CLOSE_EXTERNAL_DIALOG:
      return closeViewer(state, action.performAction);

    case VIEWER_LOADING:
      return {
        ...clearState(state),
        request: action.request,
      };

    case VIEWER_FAILED:
      console.log(`Viewer failed to load: ${action.message}`);
      return {
        ...clearState(state),
        error: 'Failed to load resource',
      };

    case VIEWER_COMPLETE:
      return viewerComplete(state, action.result, action.page);

    case VIEWER_SEARCH_DOCUMENT_START:
      return {
        ...clearSearchDocumentState(state),
        searchDocumentQuery: action.query,
      };

    case VIEWER_SEARCH_DOCUMENT_COMPLETE:
      if (state.searchDocumentQuery !== action.query) {
        return state;
      }

      return {
        ...clearSearchDocumentState(state),
        searchDocumentPages: action.pages,
        searchDocumentHighlight: action.query,
      };

    case VIEWER_SEARCH_DOCUMENT_FAILED:
      if (state.searchDocumentQuery !== action.query) {
        return state;
      }

      console.log(`Viewer failed to search document: ${action.message}`);
      return {
        ...clearSearchDocumentState(state),
        searchDocumentError: 'Failed to search documents',
      };

    case VIEWER_PAGE_SEARCH:
      return searchByPage(state, action.query);

    case VIEWER_HIGHLIGHT_QUERY:
      return {...state, searchDocumentHighlight: action.query};

    case VIEWER_ZOOM_PDF:
      return {...state, zoomPdf: action.zoomPdf};

    default:
      return state;
  }
}
