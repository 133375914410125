// -----------------------------------------------------------------------------
// Territory Stories
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Import polyfill modules first.
// These should be persistent across the whole TS application.
//
// To fix the "flatMap is not a function" error in older browsers
import 'array-flat-polyfill';
//
// Pointer events polyfill for older browsers - NTDL-951
import 'elm-pep';
// End of polyfill modules.
// -----------------------------------------------------------------------------

import React from 'react';
import ReactDOM from "react-dom";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { ConnectedRouter, push, routerMiddleware, routerReducer } from "react-router-redux";
import createHistory from "history/createBrowserHistory";
import reducers from "./reducers";
import App from "./app.jsx";

// for some reason when visibility changes mobile touch presses within the chrome
// device emulator fires twice. So closing a dialog may press the button behind.
// To fix this strange behaviour this hokey fix looks at all Material UI
// components (which are generally the things in popovers) and force them to
// not trigger the second click.
(() => {
  let orig = React.createElement;

  React.createElement = function(type, props, ...children) {
    // only wrap native elements (string-ish `type`) which have an onTouchTap prop
    if (typeof type === 'string' && props?.onTouchTap) {
      let orig = props.onTouchTap;

      props = {
        ...props,
        onTouchTap: function(e) {
          e.preventDefault();
          return orig.apply(this, arguments);
        }
      }
    }

    return orig.call(this, type, props, ...children);
  };
})();

global.run_search_app = () => {
  const history = createHistory();
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || ((f) => f);
  //const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace:true,tracelimit:25}) || ((f) => f);
  const store = createStore(
    reducers,
    window.__PRELOADED_STATE__,
    composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
  );

  // This is where the URL is re-written...
  // Only the <input type="text" /> content is used for the new URL, but,
  // we should also use type="hidden" so we can preserve the facets.
  // Also this actually defeats the normal submission of the form...
  //
  //(() => {
  //  const search_form = document.getElementById("search-form");
  //  search_form.addEventListener("submit", (e) => {
  //    e.preventDefault();
  //    const text = search_form.querySelector("[type=text]");
  //    const searchPath = window.SEARCH_PATH;
  //    store.dispatch(push(`${searchPath}?query=` + encodeURIComponent(text.value)));
  //  });
  //})();
  (() => {
    let search_form = document.getElementById('search-form');
    search_form.addEventListener('submit', function(e) {
      e.preventDefault();
      //console.log('event listener for search-form submit');
      let fullUrl = '';
      let searchPath = window.SEARCH_PATH;
      // First let's add the search query
      let text = search_form.querySelector('[type=text]');
      if ((text.value) && (text.value !== '')) {
        fullUrl += `${searchPath}?query=${encodeURIComponent(text.value)}`;
      }
      //console.log(`fullUrl = ${fullUrl}`);
      // Then add the facets - multiple choices per facet are stored
      // as an array in facet[1]
      let formData = new FormData(search_form);
      let entries = formData.entries();
      for (let facet of entries) {
        //console.log(`Found facet ${facet[0]}`);
        // Exclude empty facets, and the query facet
        if ((facet[1] !== '') && ((facet[0] !== 'query') && (facet[0] !== 'search-input'))) {
          //console.log(`Process facet ${facet[0]}`);
          for (let F of JSON.parse(facet[1])) {
            //console.log(`Adding facet ${facet[0]}=${F} to URL`);
            fullUrl += `&${facet[0]}=${encodeURIComponent(F)}`;
          }
        } else {
          //console.log(`Skipping facet ${facet[0]}`);
        }
      }
      //Replace initial & with ? in URL, just in case...
      fullUrl = fullUrl.replace(/^\&/,'?');
      //console.log(`global.run_search_app - dispatch(push(${fullUrl}))`);
      store.dispatch(push(`${fullUrl}`));
    });


  })();

  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,  document.getElementById("search-app")
  );
};
