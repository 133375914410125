export const AUTOCOMPLETE_START = 'AUTOCOMPLETE_START';
export const COLLAPSE_SEARCH_FACETS = 'COLLAPSE_SEARCH_FACETS';
export const SEARCH_COMPLETE = 'SEARCH_COMPLETE';
export const SEARCH_FAILED = 'SEARCH_FAILED';
export const SEARCH_LAST_SEARCH_URL = 'SEARCH_LAST_SEARCH_URL';
export const SEARCH_RESET_FACETS_TO_INITIAL_STATE = 'SEARCH_RESET_FACETS_TO_INITIAL_STATE';
export const SEARCH_START = 'SEARCH_START';
export const SEARCH_THUMBNAIL_SIZE = 'SEARCH_THUMBNAIL_SIZE';
export const SEARCH_LIST_SIZE = 'SEARCH_LIST_SIZE';
export const SEARCH_UPDATE_FACET = 'SEARCH_UPDATE_FACET';
export const SEARCH_UPDATE_FACETS_ONCHANGE = 'SEARCH_UPDATE_FACETS_ONCHANGE';
export const SELECT_GRID_MAP_DISPLAY = 'SELECT_GRID_MAP_DISPLAY';
export const SHOW_FILTER_RESULTS_MENU = 'SHOW_FILTER_RESULTS_MENU';

export let API_SEARCH = '/api/search';

import qs from 'query-string';
import { push, replace as urlReplace } from 'react-router-redux';

export function breakQueryString(location) {
  //console.log('Debug: breakQueryString() - location = ' + location);

  const query = qs.parse(location);
  //console.log('Debug: breakQueryString() - query #0:');
  //console.log(query);

  for (let key in query) {
    if (query[key].constructor !== Array) {
      query[key] = [query[key]];
    }
  }
  //console.log('Debug: breakQueryString() - query #1:');
  //console.log(query);

  for (let single of ['query', 'sort']) {
    if (query[single]) {
      query[single] = query[single][0];
    }
  }
  //console.log('Debug: breakQueryString() - query #2:');
  //console.log(query);

  if (query.query === '') {
    delete query.query;
  }
  //console.log('Debug: breakQueryString() - query #3:');
  //console.log(query);

  const search = {search: query};
  if (query.sort) {
    search.sort = query.sort;
    // RJG - Actually, don't do this!
    //delete query.sort;
  }
  //console.log('Debug: breakQueryString() - query #4:');
  //console.log(query);
  //console.log('Debug: breakQueryString() - search = ');
  //console.log(search);
  return search;
}

export function generateLocationFromRequest(request) {
  const location = request?.search || {};

  if (request?.sort) {
    location.sort = request.sort;
  }
  //console.log('Debug: generateLocationFromRequest() - location = ' + location);

  return qs.stringify(location);
}

/**
 * perform a search.
 * @params location full path including query string
 * @param startFrom key of last entry to resume searching at
 */
export function search(location, startAfter) {
  //console.log('Debug: search() - location = ' + location + ', startAfter = ' + startAfter);
  return async (dispatch, getState) => {
    const startAfterString = startAfter ? JSON.stringify(startAfter) : '';
    let fullSearchState = `${location}#${startAfterString}`;
    //console.log('Debug: search() - fullSearchState = ' + fullSearchState);
    fullSearchState = fullSearchState
      .replace(/query=[&#]/, '')
      .replace(/^[?]/, '');
    //console.log('Debug: search() - fullSearchState = ' + fullSearchState);
    const lastFullSearchState = getState().search.fullSearchState;
    //console.log('Debug: search() - lastFullSearchState = ' + lastFullSearchState);
    if (fullSearchState === lastFullSearchState) {
      //console.log('Debug: search() - fullSearchState === lastFullSearchState');
      return;
    }
    if (lastFullSearchState !== undefined && lastFullSearchState.indexOf(fullSearchState) === 0) {
      //console.log('Debug: search() - lastFullSearchState.indexOf(fullSearchState) = ' + lastFullSearchState.indexOf(fullSearchState));
      return;
    }

    dispatch({type: SEARCH_START, fullSearchState, location, startAfter});

    const query = breakQueryString(location);
    if (startAfter) {
      query.search_after = startAfter;
    }

    // For grid searches, limit the number of items to make load time
    // quicker, as scrolling will load more items as required.
    // Defined in search/elasticsearch_client.py
    query.limit = (getState().search.gridMapDisplay == 'grid');

    let result;
    try {
      result = await global.ntdl.ajax('post', API_SEARCH, query);
    } catch(e) {
      console.log(`AJAX Failed: ${e.text || e}`);
      console.log('API_SEARCH - failed ','query : - ',query);
      dispatch({type: SEARCH_FAILED, fullSearchState});
      return;
    }

    if (result.success == false) {

       result.results = null;
       result.total = 0;

       dispatch({
       type: SEARCH_FAILED,
       fullSearchState,
       });

       return;
    }

    const queryString = '?' + generateLocationFromRequest(result?.request || {});
    const isAllItems = Object.keys(query.search).length === 0;

    dispatch({
      type: SEARCH_COMPLETE,
      fullSearchState,
      result,
      isAllItems,
      hasQuery: query.search.query !== undefined,
      query: query,
      newSearch: startAfter ? false : true,
    });

    // the request is mangled by the server. Update the URL to reflect the
    // true query.
    if (result.success && !startAfter) {
      const searchPath = window.SEARCH_PATH;
      const newUrl = `${searchPath}${queryString}`;
      //console.log(`Debug: search() - dispatch ${SEARCH_LAST_SEARCH_URL}, newUrl=${newUrl}`);
      dispatch({type: SEARCH_LAST_SEARCH_URL, url: newUrl});

      if (location !== queryString) {
        //console.log(`Debug: search() - dispatch urlReplace, newUrl=${newUrl}`);
        dispatch(urlReplace(newUrl));
      }
    }
  };
}

export function returnToLastSearch() {
  //console.log('Debug: returnToLastSearch() - starting');
  return async (dispatch, getState) => {
    const url = getState().search.lastSearchUrl;
    if (!url) {
      return;
    }
    //console.log('Debug: returnToLastSearch() - url = ' + url);
    dispatch(push(url));
  };
}

export function searchMore(complete) {
  return async (dispatch, getState) => {
    const searchState = getState().search;

    const results = searchState.results;
    if (results.length === 0) {
      return;
    }

    const lastResult = results[results.length-1];
    //console.log(`Debug: calling search(${searchState.location},${lastResult.resume}) from search.js`);
    //console.log(`Debug: searchState.location=${searchState.location},lastResult.resume=${lastResult.resume}`);
    await search(searchState.location, lastResult.resume)(dispatch, getState);

    if (complete) {
      complete();
    }
  };
}

// Iterate over the query string and remove all matching keys
export function clearFacetInQueryString(location, key) {
  //console.log(`Debug: clearFacetInQueryString() - location=${location},key=${key}`);
  // Try to clear the key/value in the hidden field in the base
  // template. This is named after the key.
  // Get the hidden field by name, and clear it.
  let hiddenField = document.getElementById(key);
  if (hiddenField) {
    //console.log(`Clearing name and value for ${hiddenField.id}`);
    hiddenField.name = '';
    hiddenField.value = '';
  }

  let parts = breakQueryString(location).search;
  //console.log(`Debug: clearFacetInQueryString() - parts=${JSON.stringify(parts)}`);
  for (let K of Object.keys(parts)) {
    if (K == key) {
      //console.log(`Debug: clearFacetInQueryString() - Deleting ${key},${parts[key]}`);
      delete parts[K];
    }
  }
  //console.log(`Debug: clearFacetInQueryString() - new location=${qs.stringify(parts)}`);
  return qs.stringify(parts);
}

export function updateFacetInQueryString(location, key, value, enable, replace) {
  //console.log(`Debug: updateFacetInQueryString() - location=${location},key=${key},value=${value},enable=${enable},replace=${replace}`);
  const parts = breakQueryString(location).search;
  //console.log('Debug: updateFacetInQueryString() - before: parts:');
  //console.log(parts);

  // Try to add/remove the key/value in the hidden fields in the base
  // template. They are named after the key, and contain URL encoded
  // values in a JSON encoded list.

  // Get the value of the hidden field
  let hiddenField = document.getElementById(key);
  let hiddenValue = (hiddenField) ? hiddenField.value : null;
  let hiddenValueList = [];
  if (hiddenValue && hiddenValue !== '') {
    hiddenValueList = JSON.parse(hiddenValue);
  }
  if (enable) {
    // Add the new key/value
    hiddenValueList.push(encodeURIComponent(value));
    //console.log(`Debug: updateFacetInQueryString() - added ${value} to ${key} hidden input`);
  } else {
    // Remove the existing key/value
    let index = hiddenValueList.indexOf(encodeURIComponent(value));
    if (index > -1) {
      hiddenValueList.splice(index, 1);
      //console.log(`Debug: updateFacetInQueryString() - removed ${value} from ${key} hidden input`);
    } else {
      //console.log(`Debug: updateFacetInQueryString() - removing ${value} from ${key} hidden input failed, hiddenValue=${hiddenValue}`);
    }
  }
  // Set or clear the name and value of the hidden field
  if (hiddenField) {
    if (enable) {
      //console.log(`Setting name and value for ${hiddenField.id}`);
      hiddenField.name = hiddenField.id;
      hiddenField.value = JSON.stringify(hiddenValueList);
    } else {
      //console.log(`Clearing name and value for ${hiddenField.id}`);
      hiddenField.name = '';
      hiddenField.value = '';
    }
  }

  if (replace) {
    parts[key] = enable ? [value] : [];
  } else {
    parts[key] = [...(parts[key]||[])];
    parts[key].remove(value);
    if (enable) {
      parts[key].push(value);
    }
  }

  //console.log('Debug: updateFacetInQueryString() - after: parts:');
  //console.log(parts);
  //console.log('Debug: updateFacetInQueryString() - qs.stringify(parts) = ' + qs.stringify(parts));
  return qs.stringify(parts);
}

export function updateAllFacetsInQueryString(location, keyValues) {
  const query = qs.parse(location);
  let newValues = Object.assign({}, keyValues);
  for (let key of ['sort', 'query']) {
    if (query[key] !== undefined) {
      newValues[key] = query[key];
    }
  }

  //console.log('Debug: updateAllFacetsInQueryString() - qs.stringify(newValues) = ' + qs.stringify(newValues));
  return qs.stringify(newValues);
}

export function updateSort(newSortOrder) {
  //console.log('Debug: updateSort() - newSortOrder = ' + newSortOrder);
  return updateFacet('sort', newSortOrder, true, true);
}

export function changeThumbnailSize(size) {
  return {type: SEARCH_THUMBNAIL_SIZE, size};
}

export function changeListSize(listSize) {
  return {type: SEARCH_LIST_SIZE, listSize};
}

export function collapseSearchFacets(collapseFacets) {
    return {type: COLLAPSE_SEARCH_FACETS, collapseFacets};
}

export function selectGridMapDisplay(gridMapDisplay) {
    return {type: SELECT_GRID_MAP_DISPLAY, gridMapDisplay};
}

export function showFiltersResultsMenu(filterResultsMenu) {
    return { type:SHOW_FILTER_RESULTS_MENU, filterResultsMenu};
}

export function updateFacetsOnChange(facetItem) {
  return {type: SEARCH_UPDATE_FACETS_ONCHANGE,facetItem};
}

export function resetFacetsToInitialState() {
  return {type: SEARCH_RESET_FACETS_TO_INITIAL_STATE};
}

/**
 * facets are in the form:
 *  [{
 *    key: 'type',
 *    items: [
 *      {
 *        selected: false,
 *        value: 'image',
 *        count: 40
 *      }
 *    ]
 *  }]
 */
export function flattenSelectedFacets(facetArray) {
  let keyValues = {};
  for (let i of facetArray) {
    const selected = i.items.map(j => j.selected ? j.value : null);
    selected = selected.filter(j => j !== null);
    if (selected.length > 0) {
      keyValues[i.key] = selected;
    }
  }

  return keyValues;
}

export function updateFacetBatchUsingFacetArray(facetArray) {
  //console.log(`Debug:  updateFacetBatchUsingFacetArray() - facetArray=${facetArray}`);
  const flatten = flattenSelectedFacets(facetArray);
  return updateFacetBatch(flatten);
}

export function updateFacetBatch(keyValues) {
  // RJG - Must be at the top for the update to work
  // Updated results mid-way are kind of pointless anyway as
  // the position would not be preserved within the results
  //console.log("Debug: forcing window.scrollTo(0, 0); in updateFacetBatch()");
  window.scrollTo(0, 0);
  return (dispatch, getState) => {
    const lastLocation = getState().search.location;
    const query = updateAllFacetsInQueryString(lastLocation, keyValues);
    const searchPath = window.SEARCH_PATH;
    const newLocation = `${searchPath}?${query}`;
    if (newLocation != lastLocation) {
      //console.log('Debug: updateFacetBatch() - newLocation = ' + newLocation);
      dispatch(push(newLocation));
    }
  };
}

export function searchSubjectAndUpdateFacet(key, value, enable, replace) {
   //console.log("Debug: searchSubjectAndUpdateFacet() - forcing window.scrollTo(0, 0)");
   window.scrollTo(0,0);
   return (dispatch,getState) => {
      const query = updateFacetInQueryString("", key,value, enable, replace);
      const searchPath = window.SEARCH_PATH;
      const newLocation = `${searchPath}?${query}`;
      //console.log('Debug: searchSubjectAndUpdateFacet() - newLocation = ' + newLocation);
      dispatch(push(newLocation));
   };
}


export function searchCollectionAndUpdateFacet(key, value, enable, replace) {
   //console.log("Debug: searchCollectionAndUpdateFacet() - forcing window.scrollTo(0, 0)");
   window.scrollTo(0,0);
   return (dispatch,getState) => {
      const query = updateFacetInQueryString("", key,value, enable, replace);
      const searchPath = window.SEARCH_PATH;
      const newLocation = `${searchPath}?${query}`;
      //console.log('Debug: searchCollectionAndUpdateFacet() - newLocation = ' + newLocation);
      dispatch(push(newLocation));
   };
}


export function clearFacet(key) {
  //console.log(`Debug: clearFacet() - key=${key}`);
  window.scrollTo(0, 0);
  return (dispatch, getState) => {
    let lastLocation = getState().search.location;
    let query = clearFacetInQueryString(lastLocation, key);
    let searchPath = window.SEARCH_PATH;
    let newLocation = `${searchPath}?${query}`;
    if (newLocation != lastLocation) {
      //console.log('Debug: clearFacet() - newLocation = ' + newLocation);
      dispatch(push(newLocation));
    }
  };
}


export function updateFacet(key, value, enable, replace) {
  //console.log('Debug: updateFacet() - key = ' + key + ' value = ' + value + ' enable = ' + enable + ' replace = ' + replace);
  // RJG - Must be at the top of screen for the update to work
  // Updated results mid-way are kind of pointless anyway as
  // the position would not be preserved within the results
  //console.log("Debug: updateFacet() - forcing window.scrollTo(0, 0)");
  window.scrollTo(0, 0);
  return (dispatch, getState) => {
    const lastLocation = getState().search.location;
    const query = updateFacetInQueryString(lastLocation, key, value, enable, replace);
    const searchPath = window.SEARCH_PATH;
    const newLocation = `${searchPath}?${query}`;

    if (newLocation != lastLocation) {
      //console.log(`Debug: updateFacet() - lastLocation=${lastLocation},newLocation=${newLocation}`);
      dispatch(push(newLocation));
    }
  };
}
