import { routerReducer as router } from "react-router-redux"
import { combineReducers } from "redux";

import search from "./search";
import advancedFacets from "./advancedFacets";
import viewer from "./viewer";

const reducers = combineReducers({
  router,
  search,
  advancedFacets,
  viewer,
});

export default reducers;
