import { push, replace } from 'react-router-redux';
import qs from "query-string";

import ReactGA from 'react-ga';

export const VIEWER_NOOP = 'VIEWER_NOOP';
export const VIEWER_OPEN_EXTERNAL_DIALOG = 'VIEWER_OPEN_EXTERNAL_DIALOG';
export const VIEWER_CLOSE_EXTERNAL_DIALOG = 'VIEWER_CLOSE_EXTERNAL_DIALOG';
export const VIEWER_LOADING = 'VIEWER_LOADING';
export const VIEWER_FAILED = 'VIEWER_FAILED';
export const VIEWER_COMPLETE = 'VIEWER_COMPLETE';
export const VIEWER_SEARCH_DOCUMENT_START = 'VIEWER_SEARCH_DOCUMENT_START';
export const VIEWER_SEARCH_DOCUMENT_COMPLETE = 'VIEWER_SEARCH_DOCUMENT_COMPLETE';
export const VIEWER_SEARCH_DOCUMENT_FAILED = 'VIEWER_SEARCH_DOCUMENT_FAILED';
export const VIEWER_PAGE_SEARCH = 'VIEWER_PAGE_SEARCH';
export const VIEWER_HIGHLIGHT_QUERY = 'VIEWER_HIGHLIGHT_QUERY';
export const VIEWER_ZOOM_PDF = 'VIEWER_ZOOM_PDF';

const API_FULL_RECORD = '/api/full_record';
const API_PAGE_CONTENT = '/api/content';
const API_DOCUMENT_SEARCH_PREFIX = '/api/document_search';

export function openExternalLinkDialog(externalLink) {
    return { type: VIEWER_OPEN_EXTERNAL_DIALOG, externalLink, };
}

export function closeExternalLinkDialog(externalLink, performAction) {
    if (performAction) {
      return async (dispatch, getState) => {
          window.open(externalLink,"_self");
      }
    } else {
      return {type: VIEWER_CLOSE_EXTERNAL_DIALOG, performAction};
    }
}

export function viewerLoadItem(handle, order, page, ready) {
  //console.log(`Starting viewerLoadItem()`);
  //console.log(`handle=${handle},order=${order},page=${page}`);
  return async (dispatch, getState) => {

    // NTDL-686 to remove global search(elasticsearch) highlight
    // and have local search (document) highlight
    /*
    const {lastQuery} = getState().search;
    const query = lastQuery?.search?.query || '';
    if (query) {
      dispatch({type: VIEWER_HIGHLIGHT_QUERY, query});
    }
    */

    var handle_regex = /https?:\/\/hdl.handle.net\// ;
    handle = handle.replace(handle_regex,'/');

    const request = `${handle||''}/${order||''}`;
    let zoomPdf = getState().viewer.zoomPdf;
    let req = getState().viewer.request;
    // The url could be a handle/, or a handle/0 which are both the same
    //  10070/651794/
    //  10070/651794/0
    //console.log(`Testing ...`);
    //console.log(`-> req=${req}`);
    //console.log(`-> request=${request}`);
    if ((req != null) && ((req == request) || (`${req}0` == request))) {
      dispatch({type: VIEWER_COMPLETE, result: null, page: page||0, zoomPdf:zoomPdf});
      if (ready) {
        ready();
      }
      //console.log(`request=${request} matches state.viewer.request, returning`);
      return;
    } else {
      //console.log(`request=${request}, continuing viewerLoadItem() ...`);
    }

    // VIEWER_LOADING will clear the state
    dispatch({type: VIEWER_LOADING, request});

    let result;
    let query;
    try {
      query = {handle};
      if (order) {
        query.order = order;
      }

      //console.log(`setting query.content='true'`);
      query.content = 'true';

      const queryString = qs.stringify(query);
      //console.log(`queryString=${queryString}`);
      const url = `${API_FULL_RECORD}?${queryString}`;
      //console.log(`url=${url}`);
      result = await global.ntdl.ajax('get', url);
      //console.log(`result=${(result) ? 'Defined' : 'Undefined'}`);
    } catch(e) {
      const message = e.text || e;
      //console.log('API_FULL_RECORD - failed -url:-',url);
      console.log('error message - ',message);
      dispatch({type: VIEWER_FAILED, message});
      if (ready) {
        ready();
      }
      return;
    }

    if (!result?.success || result?.total === 0) {
      //console.log(`get url ${API_FULL_RECORD}?${qs.stringify(query)} failed`);
      //console.log(`result.success = ${result?.success}`);
      //console.log(`result.total = ${result?.total}`);
      dispatch({type: VIEWER_FAILED, message: 'invalid response from server',});
      if (ready) {
        ready();
      }
      return;
    } else {
      //console.log(`get url ${API_FULL_RECORD}?${qs.stringify(query)} succeeded`);
      //console.log(`result.success = ${result?.success}`);
      //console.log(`result.total = ${result?.total}`);
    }

    //console.log(`try getting page.content=content`);
    try {
      const queryString = qs.stringify({
        handle,
        group: order|0,
        page: page || 0,
      });
      const url = `${API_PAGE_CONTENT}?${queryString}`;
      const content = await global.ntdl.ajax('get', url);
      const page = result?.results?.[0]?.pages?.[page || 0];
      if (page) {
        page.content = content;
        //console.log(`got page, content=${(content) ? 'Defined' : 'Undefined'}`);
      } else {
        //console.log(`did not get page`);
      }
    } catch(e) {
      const message = e.text || e;
       console.log('API_PAGE_CONTENT - failed -  url : - ',url);
       console.log('error message - ',message);
    }

    dispatch({type: VIEWER_COMPLETE, result, page: page||0, zoomPdf});
    if (ready) {
      ready();
    }
    //console.log(`Finished viewerLoadItem()`);
  }
}

let _lastSearchLocation = null;

// RJG - View an external link in a new tab
// RJG - Used by the external-link.jsx dialog
export function viewLinkNewTab(external_link) {
  return async(dispatch,getState) =>{
    if (external_link) {
      //console.log('Loading External Link=', item.external_link);
      // Generate Google Analytics GA Event for the click
      const trackingId = "UA-163662707-1";
      ReactGA.initialize(trackingId);
      ReactGA.pageview(external_link);
      ReactGA.event({
        category: 'Click',
        action: 'ViewItem',
        label: 'View Item, External Link, New Tab'
      });
      window.open(external_link, '_blank');
    } else {
      console.log('Nothing to load in new tab');
    }
  }
}

// RJG - View an item in a new tab
// RJG - Used with ctrl-click and middle-click
// RJG - from results, and from related items
export function viewItemNewTab(item) {
  return async(dispatch,getState) =>{
    if (item.external_link) {
        //console.log('Loading External Link=', item.external_link);
        // Generate Google Analytics GA Event for the click
        const trackingId = "UA-163662707-1";
        ReactGA.initialize(trackingId);
        ReactGA.pageview(item.external_link);
        ReactGA.event({
          category: 'Click',
          action: 'ViewItem',
          label: 'View Item, External Link, New Tab'
        });
        window.open(item.external_link, '_blank');
    } else if (item.handle) {
        //console.log('Loading New Handle=', item.handle, item.id);
        var relativeURL = item.handle;
        if ( item.handle ) {
            var relativeURL_regex = /https?:\/\/hdl.handle.net\// ;
            relativeURL = relativeURL.replace(relativeURL_regex, '/');
        }
        //console.log('Loading New Handle=', relativeURL);
        // Generate Google Analytics GA Event for the click
        const trackingId = "UA-163662707-1";
        ReactGA.initialize(trackingId);
        ReactGA.pageview(relativeURL);
        ReactGA.event({
          category: 'Click',
          action: 'ViewItem',
          label: 'View Item, Internal Link, New Tab'
        });
        window.open(relativeURL, '_blank');
    } else {
        console.log('Nothing to load in new tab, id=' + item.id)
    }
  }
}


export function viewItem(item,page) {
    //console.log(`viewItem() item=${(item) ? 'Defined' : 'Undefined'}, page=${page}`);
    return async(dispatch,getState) => {
        if( item.external_link) {
            dispatch(openExternalLinkDialog(item.external_link));
            return;
        }
        let url =  item.handle;
        var handle_regex = /https?:\/\/hdl.handle.net\// ;
        url = url.replace(handle_regex,'/');

        if (page === undefined) {
            page = 0;
            /*
            if (item.pages && item.pages.length > 0) {
                page = item.pages[0].order;
            }
            */
        }

        // ------------------------------------------------------------------
        // viewer = 0   - image
        // viewer = 1   - pdf
        // viewer = 2/5 - fullRecord, reference
        // viewer = 3   - video
        // viewer = 4   - audio
        // viewer = 6   - epub viewer
        // ------------------------------------------------------------------

        if ((item.viewer == 2) || (item.viewer == 5)) {
            //url += `/reference`;
        } else {
            if (item.order > 0 || page !== 0) {
                url += `/${item.order||0}`;
            }
            if (page > 0) {
                url += `/${page}`;
            }
        }
        if (item.viewer == 1) {
            if((item.order == 0) && (page == 0)) {
                url += `/0/0`;
            }
        }

        //console.log('Loading Internal Link=' + url);

        // Generate Google Analytics GA Event for the click
        const trackingId = "UA-163662707-1";
        ReactGA.initialize(trackingId);
        ReactGA.pageview(url);
        ReactGA.event({
          category: 'Click',
          action: 'ViewItem',
          label: 'View Item, Internal Link, Same Tab'
        });

	dispatch(push(url));
    }
}


export function exitViewItem() {
  return async (dispatch, getState) => {
    if (!_lastLocation) {
      return;
    }
    dispatch(push(_lastSearchLocation));
  };
}

export function viewerHighlightByQueryInLocation(location) {
    const locationQuery = qs.parse(location);

    let query = locationQuery.query;

    if (!query) {
      return {type: VIEWER_NOOP};
    }

    if (query.constructor === Array) {
      query = query[0];
    }

    return {type: VIEWER_HIGHLIGHT_QUERY, query};
}

export function viewerSearchDocument(query) {
  return async (dispatch, getState) => {
    const state = getState();
    const record = state.viewer.record;

    if (!record) {
      dispatch({type: VIEWER_SEARCH_DOCUMENT_FAILED, message: 'no record specified to search'});
      return;
    }


    dispatch({type: VIEWER_SEARCH_DOCUMENT_START, query});

    /*
    if (query === '' && record.all) {
      dispatch({type: VIEWER_SEARCH_DOCUMENT_COMPLETE, pages: record.all, query});
      return;
    }*/

    let url = `${API_DOCUMENT_SEARCH_PREFIX}/${record.id}?query=${encodeURIComponent(query||'')}`;
    //console.log(`viewerSearchDocument() url=${url}`);

    let pages;
    //console.log(`viewerSearchDocument() try getting pages ...`);
    try {
      //console.log(`Getting pages: ${url}`);
      pages = await global.ntdl.ajax('get', url);
    } catch (e) {
      const message = e.text || e;
      console.log('{API_DOCUMENT_SEARCH_PREFIX - failed - url',url);
      console.log('error message - ',message);
      dispatch({type: VIEWER_SEARCH_DOCUMENT_FAILED, message, query});
      return;
    }
    //console.log(`viewerSearchDocument() pages=${(pages) ? 'Defined' : 'Undefined'}`);

    if (!pages) {
      dispatch({type: VIEWER_SEARCH_DOCUMENT_FAILED, message: 'failed to receive pages', query});
      return;
    }

    if (query === '') {
      record.all = pages;
    }

    dispatch({type: VIEWER_SEARCH_DOCUMENT_COMPLETE, pages, query});
  };
}

export function viewerDocumentPageSearch(query) {
  return {type: VIEWER_PAGE_SEARCH, query};
}

export function viewerZoomPdf(zoomPdf) {
  // Generate Google Analytics GA Event for the PDF zoom event
  const trackingId = "UA-163662707-1";
  ReactGA.initialize(trackingId);
  ReactGA.event({
    category: 'Click',
    action: 'Zoom',
    label: 'Zoom PDF'
  });
  return {type: VIEWER_ZOOM_PDF,zoomPdf};
}

